import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate';
import { getPractices, updatePractice, disableEnablePractice, startEmbeddingForPractice, downloadPractices } from '../actions/practiceAction';
import jwtSimple from "jwt-simple";
import {
    Table, TableHead, TableBody, TableHeaderElement, TableDataElement, TableRow, TableContainer, PageContainer, SubHeader, FilterContainer, StyledLabel, PaginationContainer, InputContainer, PageSizeContainer, StyledInput,
    Icon, ArrowButton, ButtonContainer, NoDataFound, PageCountContainer, CountTag, Capitalize
} from '../components';
import { withRouter } from '../components/WithRouter';
import Spinner from '../components/Spinner';
import Modal from 'react-bootstrap/Modal';
import { DropDownStyle, pageSizeOptions, states } from '../helpers';
import Select from 'react-select';
import { checkDataIsValid } from '../CheckData';
import Vector from '../assets/images/vector.svg';
import { getLocations, startEmbedding } from '../actions/locationAction';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import DownloadIcon from '../assets/images/download.svg';


class Practice extends Component {
    state = {
        allPractices: [],
        pageNumber: 0,
        pageSize: 15,
        pageCount: 0,
        totalCount: 0,
        userId: '',
        edit: false,
        practiceForEdit: {},
        sortBy: 'lowerPracticeName',
        order: 'asc',
        nameOrAdmin: '',
        isModalOpen: false,
        billingStatus: 'all',
        isLocationModalOpen: false,
        locationFetchingInProgress: false,
        allLocations: []
    };
    componentDidMount() {
        const { dispatch } = this.props;
        const params = new URLSearchParams(window.location.search);
        let practiceId = params.get('locationId') || '';
        if (practiceId) {
            const { REACT_APP_JWT_SECRET } = process.env;
            practiceId = jwtSimple.decode(practiceId, REACT_APP_JWT_SECRET);
            this.setState({ practiceId });
        };
        let userId = params.get('userId') || '';
        if (userId) {
            const { REACT_APP_JWT_SECRET } = process.env;
            userId = jwtSimple.decode(userId, REACT_APP_JWT_SECRET);
            this.setState({ userId });
        };
        dispatch(getPractices({ ...this.state, practiceId, userId }));
    };
    componentWillReceiveProps = (updatedProps) => {
        const { pageSize } = this.state;
        const { practices, practicesCount } = updatedProps;
        const pageCount = Math.ceil(practicesCount / pageSize);
        this.setState({
            allPractices: practices,
            totalCount: practicesCount,
            pageCount: pageCount
        });
    };
    handlePageClick = async (event) => {
        const { dispatch } = this.props;
        const { pageSize } = this.state;
        this.setState({
            pageNumber: event.selected + 1
        });
        await dispatch(getPractices({ ...this.state, pageNumber: event.selected + 1, pageSize: pageSize }));
    };
    handleFilterInputChange = async (value) => {
        this.setState({
            pageNumber: 1,
            pageSize: 15,
            nameOrAdmin: value
        });
    };
    getFilteredData = async (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        await dispatch(getPractices(this.state));
    };
    resetFilter = async () => {
        const { dispatch } = this.props;
        this.setState({
            pageNumber: 0,
            pageSize: 15,
            edit: false,
            practiceForEdit: {},
            userId: '',
            sortBy: 'lowerPracticeName',
            order: 'asc',
            nameOrAdmin: '',
            billingStatus: 'all'
        }, () => {
            dispatch(getPractices(this.state));
        });
    };
    handlePageSizeInputChanges = async (value) => {
        const { dispatch } = this.props;
        this.setState({
            pageSize: value,
            pageNumber: 1,
        });
        await dispatch(getPractices({ ...this.state, pageNumber: 1, pageSize: value }));
    };
    handelEditRow = (practice) => {
        this.setState({
            edit: true,
            practiceForEdit: practice
        });
    };
    handlePracticeDataChange = (value) => {
        let { practiceForEdit } = this.state;
        this.setState({
            practiceForEdit: { ...practiceForEdit, practiceAdmin: value }
        })
    };
    updatePractice = async () => {
        const { dispatch } = this.props;
        const { practiceForEdit } = this.state;
        const isValid = checkDataIsValid({ email: practiceForEdit.practiceAdmin });
        if (isValid) {
            const data = await dispatch(updatePractice(practiceForEdit));
            if (data) {
                await dispatch(getPractices(this.state));
                this.setState({
                    edit: false,
                    practiceForEdit: {}
                });
            };
        };
    };
    disableEnablePractice = async () => {
        const { dispatch } = this.props;
        const { practiceForEdit } = this.state;
        await dispatch(disableEnablePractice(practiceForEdit));
        await dispatch(getPractices(this.state));
        this.handelModelClose();
    };
    handleSortByAndOrderChange = async (sortBy, order) => {
        const { dispatch } = this.props;
        this.setState({
            sortBy,
            order,
            pageNumber: 1,
            pageSize: 15
        });
        await dispatch(getPractices({ ...this.state, pageNumber: 1, pageSize: 15, sortBy, order }));
    };
    goTo = async (url, id, inComplete) => {
        inComplete = inComplete ? inComplete : false;
        const { REACT_APP_JWT_SECRET } = process.env;
        const { navigate } = this.props;
        navigate(url + '?practiceId=' + jwtSimple.encode(id, REACT_APP_JWT_SECRET) + '&inComplete=' + inComplete);
    };
    handelModelClose = () => {
        this.setState({
            practiceForEdit: {},
            isModalOpen: false,
            isLocationModalOpen: false
        });
    };
    viewLocations = async (practice) => {
        this.setState({
            practiceForEdit: practice,
            locationFetchingInProgress: true
        });
        const { dispatch } = this.props;
        const { data } = await dispatch(getLocations({ practiceId: practice._id, pageSize: 100, pageNumber: 1 }));

        this.setState({
            locationFetchingInProgress: false,
            allLocations: data,
            isLocationModalOpen: true
        });
    };
    startEmbeddingForLocation = async (e, locationId) => {
        const { dispatch } = this.props;
        const { allLocations, practiceForEdit } = this.state;
        const tempLocation = allLocations.map((location) => {
            if (location._id === locationId) location.embeddingStatus = 'In Progress';
            return location;
        });
        this.setState({ allLocations: tempLocation });
        const result = await dispatch(startEmbedding(locationId));
        if (result) {
            toast.success('Embedding Successful.');
        };
        const { data } = await dispatch(getLocations({ practiceId: practiceForEdit._id, pageSize: 100, pageNumber: 1, }));
        this.setState({
            allLocations: data,
        });
    };
    startEmbeddingForPractice = async (e) => {
        const { dispatch } = this.props;
        const { practiceForEdit: { _id } } = this.state;
        const result = await dispatch(startEmbeddingForPractice(_id));
        if (result) {
            toast.success('Embedding Started.');
        };
        setTimeout(async () => {
            const { data } = await dispatch(getLocations({ practiceId: _id, pageSize: 100, pageNumber: 1, }));
            this.setState({
                allLocations: data,
            });
        }, 500);
    };
    downloadData = async () => {
        try {
            const { dispatch } = this.props;
            await dispatch(downloadPractices({ ...this.state }));
        } catch (error) {
            console.log("🚀 ~ Location ~ downloadData= ~ error:", error);
        }
    };
    render() {
        const { allPractices, pageSize, pageCount, edit, practiceForEdit, pageNumber, sortBy, order, nameOrAdmin, isModalOpen, totalCount, billingStatus, isLocationModalOpen, allLocations } = this.state;
        const { loading } = this.props;
        const startCount = ((pageNumber ? pageNumber - 1 : 0) * pageSize) + 1;
        const endCount = ((pageNumber ? pageNumber - 1 : 0) + 1) * pageSize;
        let startEmbeddingDisable = true;
        if (allLocations && allLocations.length) {
            allLocations.forEach((location) => {
                if ((location.embeddingStatus !== 'Completed' && location.embeddingStatus !== 'In Progress') || (location.embeddingStatus === 'Completed' && location.isLibraryStepUpdated)) {
                    if (location.didCompleteLibrarySetup) {
                        startEmbeddingDisable = false;
                    };
                };
            });
        };
        return (
            <PageContainer>
                {loading && <Spinner />}
                <SubHeader>
                    <FilterContainer>
                        <InputContainer>
                            <StyledLabel>Practice Name/Admin</StyledLabel>
                            <StyledInput
                                type="text"
                                value={nameOrAdmin}
                                onChange={(e) => this.handleFilterInputChange(e.target.value)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <StyledLabel>&ensp;</StyledLabel>
                            <ButtonContainer>
                                <Button variant='success' disabled={!nameOrAdmin.length} type='submit' onClick={this.getFilteredData} className='mx-1' >
                                    <Icon className='fa fa-search'></Icon>
                                </Button>
                                <Button variant='secondary' onClick={this.resetFilter} className='mx-1' type='button'>
                                    <Icon className='fa fa-undo'></Icon>
                                </Button>
                                <Button variant='primary' onClick={this.downloadData} className='mx-1' type='button'>
                                    <img src={DownloadIcon} alt="" />
                                </Button>
                            </ButtonContainer>
                        </InputContainer>
                    </FilterContainer>
                    <PageCountContainer>
                        <p>{startCount}-{endCount > totalCount ? totalCount : endCount} of {totalCount}</p>
                    </PageCountContainer>
                    <PageSizeContainer>
                        <StyledLabel>&ensp;</StyledLabel>
                        <div>
                            <Select
                                styles={DropDownStyle}
                                defaultValue={pageSizeOptions.filter((val) => val.value === pageSize)}
                                value={pageSizeOptions.filter((val) => val.value === pageSize)}
                                options={pageSizeOptions}
                                onChange={(e) => this.handlePageSizeInputChanges(e.value)}
                            />
                        </div>
                    </PageSizeContainer>
                </SubHeader>
                <TableContainer>
                    <Table className="table  table-bordered ">
                        <TableHead>
                            <TableRow>
                                <TableHeaderElement className='w-20' scope="col">Practice Name
                                    {sortBy !== 'lowerPracticeName' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerPracticeName', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'lowerPracticeName' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerPracticeName', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'lowerPracticeName' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('lowerPracticeName', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-20' scope="col">Practice Admin Email
                                    {sortBy !== 'practiceAdmin' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('practiceAdmin', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'practiceAdmin' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('practiceAdmin', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'practiceAdmin' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('practiceAdmin', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-20 text-center' scope="col">Billing Status
                                    {sortBy !== 'billingFailed' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('billingFailed', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'desc' && sortBy === 'billingFailed' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('billingFailed', 'asc')} className='bi bi-sort-alpha-up text-success'></Icon>}
                                    {order === 'asc' && sortBy === 'billingFailed' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('billingFailed', 'desc')} className='bi bi-sort-alpha-down text-success'></Icon>}

                                    {billingStatus === 'all' && <Icon onClick={(e) => {
                                        this.setState({ billingStatus: 'active' }, () => {
                                            this.getFilteredData(e)
                                        });
                                    }} className="bi bi-square text-secondary"></Icon>}
                                    {billingStatus === 'active' && <Icon onClick={(e) => {
                                        this.setState({ billingStatus: 'failed' }, () => {
                                            this.getFilteredData(e)
                                        });
                                    }} className="bi bi-check-square text-success"></Icon>}
                                    {billingStatus === 'failed' && <Icon onClick={(e) => {
                                        this.setState({ billingStatus: 'all' }, () => {
                                            this.getFilteredData(e)
                                        });
                                    }} className="bi bi-x-square text-danger"></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-15 text-center' scope="col">Locations Count
                                    {sortBy !== 'locationsCount' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('locationsCount', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'locationsCount' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('locationsCount', 'desc')} className='bi bi-sort-numeric-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'locationsCount' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('locationsCount', 'asc')} className='bi bi-sort-numeric-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-15 text-center' scope="col">Users Count
                                    {sortBy !== 'usersCount' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('usersCount', 'asc')} className='bi bi-arrow-down-up text-dark'></Icon>}
                                    {order === 'asc' && sortBy === 'usersCount' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('usersCount', 'desc')} className='bi bi-sort-numeric-down text-success'></Icon>}
                                    {order === 'desc' && sortBy === 'usersCount' &&
                                        <Icon onClick={() => this.handleSortByAndOrderChange('usersCount', 'asc')} className='bi bi-sort-numeric-up text-success'></Icon>}
                                </TableHeaderElement>
                                <TableHeaderElement className='w-10 text-center' scope="col">Action</TableHeaderElement>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allPractices && allPractices.map((practice) =>
                                <TableRow rowColor={(practice.subscriptionId && !practice.billingFailed) ? practice.librarySetupImCompleteLocations?.length && '#ffff009c' : '#ff020269'} key={practice._id}>
                                    <TableDataElement className='w-20'>{practice.name}</TableDataElement>
                                    <TableDataElement className='w-20'>{edit && practiceForEdit._id === practice._id ?
                                        <StyledInput
                                            className='w-100'
                                            type='text'
                                            value={practiceForEdit.practiceAdmin ? practiceForEdit.practiceAdmin : ''}
                                            onChange={(e) => this.handlePracticeDataChange(e.target.value)}
                                        />
                                        :
                                        practice.practiceAdmin ? practice.practiceAdmin : '--'}</TableDataElement>
                                    <TableDataElement className='w-20 text-center'>
                                        {practice.subscriptionId ? practice.billingFailed === false ? 'Active' : 'Failed' : 'Pending'}
                                    </TableDataElement>
                                    <TableDataElement className='w-15 text-center'>
                                        <CountTag color={'#4FB973'} onClick={(e) => this.goTo('/location', practice._id)}>
                                            {practice.locationsCount ?
                                                practice.locationsCount : '0'}
                                        </CountTag>
                                        {practice.librarySetupImCompleteLocations?.length ?
                                            <CountTag color={'red'} className='ms-2' onClick={(e) => this.goTo('/location', practice._id, true)}>
                                                ({practice.librarySetupImCompleteLocations.length})
                                            </CountTag>
                                            :
                                            <CountTag color={'red'} hoverOff={true} className='ms-2'>
                                                (0)
                                            </CountTag>
                                        }
                                        <Icon className="fa fa-eye text-success ms-1" onClick={() => this.viewLocations(practice)} />
                                    </TableDataElement>
                                    <TableDataElement className='w-15 text-center'>
                                        <ArrowButton onClick={(e) => this.goTo('/users', practice._id)}>{practice.usersCount ? practice.usersCount : '0'}</ArrowButton>
                                    </TableDataElement>
                                    <TableDataElement className='w-10 text-center'>
                                        {edit && practiceForEdit._id === practice._id ? <>
                                            <Icon onClick={() => this.updatePractice()} className='fa fa-save text-success'></Icon>
                                            <Icon className='fa fa-undo text-danger' onClick={(e) => this.setState({ edit: false })}
                                            />
                                        </> : <Icon onClick={(e) => this.handelEditRow(practice)} className='fa fa-pencil text-success' />}
                                        {practice.isActive === false ?
                                            <Icon
                                                onClick={(e) => this.setState({
                                                    practiceForEdit: { ...practice, isActive: true },
                                                    isModalOpen: true
                                                })}
                                                className='bi bi-person-x-fill text-danger' title='Click here to enable practice' />
                                            :
                                            <Icon
                                                onClick={(e) => this.setState({
                                                    practiceForEdit: { ...practice, isActive: false },
                                                    isModalOpen: true
                                                })}
                                                className='bi bi-person-check-fill text-success' title='Click here to disable practice' />
                                        }
                                    </TableDataElement>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {
                        (!allPractices || !allPractices.length) &&
                        <NoDataFound>{loading ? 'Loading...' : 'No Data Found'}</NoDataFound>
                    }
                </TableContainer>
                <PaginationContainer>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next >"
                        onPageChange={this.handlePageClick}
                        pageRangeDisplayed={10}
                        pageCount={pageCount}
                        previousLabel="< Previous"
                        renderOnZeroPageCount={null}
                        forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item hide"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link nextAndPrev"
                        nextClassName="page-item"
                        nextLinkClassName="page-link nextAndPrev"
                        activeClassName="active"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                    />
                </PaginationContainer>
                {/* Warning message */}
                <Modal
                    show={isModalOpen}
                    onHide={this.handelModelClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>Do you want to do this action?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <button className='btn btn-danger' onClick={this.handelModelClose}>
                            Discard and close
                        </button>
                        <button onClick={this.disableEnablePractice} className='btn btn-success'>Submit and close</button>
                    </Modal.Footer>
                </Modal>
                {/* Location details  */}
                <Modal
                    show={isLocationModalOpen}
                    onHide={this.handelModelClose}
                    backdrop="static"
                    keyboard={false}
                    size='xl'
                >
                    <Modal.Header>
                        <Modal.Title className='text-center w-100'>Locations of '{practiceForEdit.name}'</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex justify-content-end mb-1'>
                            <button disabled={startEmbeddingDisable} className='btn btn-outline-success' type='button' onClick={this.startEmbeddingForPractice}>
                                Start Embedding For Practice
                            </button>
                        </div>
                        <TableContainer maxHeight='65vh'>
                            <Table className="table table-bordered">
                                <TableHead>
                                    <TableRow>
                                        <TableHeaderElement className='w-25' scope="col">Name</TableHeaderElement>
                                        <TableHeaderElement className='w-20' scope="col">Type</TableHeaderElement>
                                        <TableHeaderElement className='w-20' scope="col">State</TableHeaderElement>
                                        <TableHeaderElement className='w-25' scope="col">Embedding</TableHeaderElement>
                                        <TableHeaderElement className='w-10 text-center' scope="col">Action</TableHeaderElement>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allLocations && allLocations.map((location) =>
                                        <TableRow key={location._id}>
                                            <TableDataElement className='w-25'>{location.name}</TableDataElement>
                                            <TableDataElement className='w-20'>
                                                <Capitalize>{location.type ? location.type : '--'}</Capitalize>
                                            </TableDataElement>
                                            <TableDataElement className='w-20'>{location.address.state ? states.find((state) => state.value === location.address.state).label : '--'}</TableDataElement>
                                            <TableDataElement className='w-25'>
                                                {location.didCompleteLibrarySetup ?
                                                    (location.isLibraryStepUpdated && location.embeddingStatus !== 'In Progress') ?
                                                        'Required' : location.embeddingStatus : 'Library setup not completed.'
                                                }
                                            </TableDataElement>
                                            <TableDataElement className='w-10 text-center'>
                                                <button disabled={(!location.isLibraryStepUpdated || location.embeddingStatus === 'In Progress') && location.embeddingStatus !== 'Error'} className='btn btn-outline-success' onClick={(e) => this.startEmbeddingForLocation(e, location._id)} type="button">
                                                    {(location.embeddingStatus === 'In Progress') ?
                                                        <div className="spinner-border spinner-border-sm" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div> : <img height="20" width="20" src={Vector} alt="Start embedding." title="Start embedding." />
                                                    }
                                                </button>
                                            </TableDataElement>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-danger' onClick={this.handelModelClose}>
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>
            </PageContainer>
        );
    };
};

const mapStateToProps = (state) => ({
    practices: state.practices.practices.data,
    practicesCount: state.practices.practices.count,
    loading: state.practices.loading
});

export default withRouter(connect(mapStateToProps)(Practice));