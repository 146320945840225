import axios from 'axios'
import actions from './actionTypes';
import { apiUrl } from '../helpers';
import { toast } from 'react-toastify';

const getPracticesSuccess = (practices) => (dispatch) => {
    dispatch({
        type: actions.GET_PRACTICES_SUCCESS,
        practices
    });
};
const getPracticesError = () => (dispatch) => {
    dispatch({
        type: actions.GET_PRACTICES_ERROR
    });
};
const practiceRequest = () => (dispatch) => {
    dispatch({
        type: actions.PRACTICE_REQUEST,
    });
};
export const getPractices = (params) => async (dispatch) => {
    try {
        dispatch(practiceRequest());
        const { pageSize, pageNumber, nameOrAdmin, sortBy, order, userId, practiceId, billingStatus } = params;
        let apiEndPoint = `/api/allPractice`;
        if (pageSize) {
            apiEndPoint += '?pageSize=' + pageSize;
        };
        if (pageNumber) {
            apiEndPoint += '&pageNumber=' + pageNumber;
        };
        if (nameOrAdmin) {
            apiEndPoint += '&nameOrAdmin=' + encodeURIComponent(nameOrAdmin);
        };
        if (practiceId) {
            apiEndPoint += '&practiceId=' + practiceId;
        };
        if (userId) {
            apiEndPoint += '&userId=' + userId;
        };
        if (billingStatus && billingStatus !== 'all') {
            apiEndPoint += '&billingStatus=' + billingStatus;
        };
        if (sortBy && order) {
            apiEndPoint += '&sortBy=' + sortBy + '&order=' + order;
        };
        const { data: { payload } } = await axios.get(apiUrl(apiEndPoint));
        dispatch(getPracticesSuccess(payload));
    } catch (error) {
        dispatch(getPracticesError());
    }
};
export const downloadPractices = (params) => async () => {
    try {
        const { nameOrAdmin, sortBy, order, practiceId, userId, billingStatus } = params;
        let apiEndPoint = `/api/export/practices?`;
        if (sortBy && order) {
            apiEndPoint += 'sortBy=' + sortBy + '&order=' + order;
        };
        if (nameOrAdmin) {
            apiEndPoint += '&nameOrAdmin=' + encodeURIComponent(nameOrAdmin);
        };
        if (practiceId) {
            apiEndPoint += '&practiceId=' + practiceId;
        };
        if (userId) {
            apiEndPoint += '&userId=' + userId;
        };
        if (billingStatus && billingStatus !== 'all') {
            apiEndPoint += '&billingStatus=' + billingStatus;
        };

        const { data } = await axios.get(apiUrl(apiEndPoint), { responseType: 'blob' });

        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([data]));

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Practices.xlsx'); // Set the file name

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up by removing the link and revoking the object URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.log("🚀 ~ downloadPractices ~ error:", error)
    }
};
export const updatePractice = (practice) => async () => {
    try {
        const { data } = await axios.put(apiUrl(`/api/practice/${practice._id}`), practice);
        toast.success('Practice data updated successfully.');
        return data;
    } catch (error) {
        if (error.response.status === 409) {
            toast.error('Email id already exists.');
            return false;
        }
        toast.error('Error in practice update.');
        return false;
    }
};
export const disableEnablePractice = (practice) => async () => {
    try {
        const { _id, isActive } = practice;
        const { data } = await axios.delete(apiUrl(`/api/practice/${_id}?active=${isActive}`));
        toast.success('Practice data updated successfully.');
        return data;
    } catch (error) {
        toast.error('Error in practice update.');
        return false;
    }
};

export const startEmbeddingForPractice = (practice) => async () => {
    try {
        const { data } = await axios.put(apiUrl('/api/practice/embedding'), { practice });
        return data;
    } catch (error) {
        console.error(error);
        toast.error('Error in emending.');
        return false;
    }
}